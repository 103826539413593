<div class="desc">
    Summary of how many domains are currently in various EPP statuses.
</div>

<div class="row">
    <span class="label">Total domains</span>
    <span class="value">{{summary()?.total | number}}</span>
</div>
<div class="row">
    <span class="label">clientProhibited (locked)</span>
    <span class="value">{{summary()?.clientProhibited | number}}</span>
</div>
<div class="row">
    <span class="label">ok (unlocked)</span>
    <span class="value">{{summary()?.ok | number}}</span>
</div>
<div class="row">
    <span class="label">pendingDelete</span>
    <span class="value">{{summary()?.pendingDelete | number}}</span>
</div>
<div class="row">
    <span class="label">serverHold</span>
    <span class="value">{{summary()?.serverHold | number}}</span>
</div>
<div class="row">
    <span class="label">serverDeleteProhibited</span>
    <span class="value">{{summary()?.serverDeleteProhibited | number}}</span>
</div>
<div class="row">
    <span class="label">serverUpdateProhibited</span>
    <span class="value">{{summary()?.serverUpdateProhibited | number}}</span>
</div>
<div class="row">
    <span class="label">serverTransferProhibited</span>
    <span class="value">{{summary()?.serverTransferProhibited | number}}</span>
</div>

