
<p>Retrieve all the available information for a single domain</p>

@if (haveForm()) {
    <form [formGroup]="form"
          (ngSubmit)="submit()">

        <mat-form-field>
            <div id="input_row">
                <input matInput
                       formControlName="domain"
                       placeholder="Domain name or partial name"
                       (keydown.escape)="clearSearchIfClosed()"
                       (keydown.enter)="submitIfClosed()"
                       [matAutocomplete]="auto">
                @if (searching()) {
                    <mat-spinner id="domain_spinner"
                                 [diameter]="14"></mat-spinner>
                }
                @if (formSignals.value('domain')()) {
                    <button class="close_btn"
                            matSuffix
                            mat-icon-button
                            aria-label="Clear"
                            (click)="clearSearch()">
                        <mat-icon>close</mat-icon>
                    </button>
                }
            </div>
            <mat-autocomplete #auto="matAutocomplete"
                              panelWidth="312px"
                              autoActiveFirstOption>
                @for (match of filteredMatches(); track $index) {
                    <mat-option [value]="match">
                        {{match}}
                    </mat-option>
                }
            </mat-autocomplete>
            <mat-error>{{formSignals.errors('domain')()}}</mat-error>
        </mat-form-field>

        <button mat-raised-button
                color="primary"
                [disabled]="!formSignals.formValid() || isBusy()"
                type="submit">Get Info</button>
    </form>
}

@if (domainInfo()) {
    <div class="results">
        <div class="result_row">
            <span class="label">Name</span>
            <span class="value domain"
                  [ngClass]="{not_in_registry: !domainInfo()!.in_registry}">{{domainInfo()!.name}}</span>
        </div>
        <div class="result_row">
            <span class="label">NS group</span>
            <span class="value">{{domainInfo()!.ns_group.name}}</span>
        </div>
        <div class="result_row">
            <ul class="hosts">
                @for (host of domainInfo()!.ns_group.hosts; track $index) {
                    <li>{{host}}</li>
                }
            </ul>
            @if (nsMismatch()) {
                <ul class="hosts error">
                    @for (host of eppDomainInfo()?.ns_hosts; track $index) {
                        <li><mat-icon>error</mat-icon> {{host}}</li>
                    }
                </ul>
            }
        </div>
        <div class="result_row">
            <span class="label">In registry</span>
            <span class="value">{{domainInfo()!.in_registry? 'yes':'no'}}</span>
            @if (inRegistryMismatch()) {
                <span class="error"><mat-icon>error</mat-icon> EPP: {{eppDomainInfo()?.in_registry}}</span>
            }
            @if (!eppBusy() && !isBusy()) {
                <mat-checkbox id="in_registry_chk"
                              [disabled]="!isAdmin"
                              [(ngModel)]="allowToggleInRegistry"
                              matTooltip="Allow toggling in-registry flag"></mat-checkbox>
                <button id="in_registry"
                        mat-raised-button
                        color="warn"
                        type="button"
                        [disabled]="!domainInfo() || !allowToggleInRegistry() || !isAdmin"
                        (click)="toggleInRegistry()">{{getInRegistryButtonLabel()}}</button>
            }
        </div>
        <div class="result_row">
            <span class="label">Pick</span>
            <span class="value">{{domainInfo()!.pick? 'yes':'no'}}</span>
        </div>
        <div class="result_row">
            <span class="label">Locked</span>
            <span class="value">{{isLocked()? 'yes':'no'}}</span>
        </div>
        <div class="result_row">
            <span class="label">In inquiry</span>
            <span class="value">{{domainInfo()!.in_inquiry? 'yes':'no'}}</span>
            @if (!eppBusy() && !isBusy()) {
                <button id="in_inquiry"
                        mat-raised-button
                        color="accent"
                        type="button"
                        [disabled]="!domainInfo() || !isAdmin"
                        (click)="toggleInInquiry()">{{getInInquiryButtonLabel()}}</button>
            }
        </div>

        <div class="result_row">
            <span class="label">Registered</span>
            <span class="value">{{formatTimestamp(domainInfo()!.registered)}}</span>
            @if (registeredMismatch()) {
                <span class="error"><mat-icon>error</mat-icon> EPP: {{formatTimestamp(eppDomainInfo()?.created)}}</span>
            }
        </div>
        <div class="result_row">
            <span class="label">Expires</span>
            <span class="value">{{formatTimestamp(domainInfo()?.expires)}}</span>
            @if (expiresMismatch()) {
                <span class="error"><mat-icon>error</mat-icon> EPP: {{formatTimestamp(eppDomainInfo()?.expires)}}</span>
            }
            @if (anyDateMismatch()) {
                <button id="sync_dates"
                        mat-raised-button
                        color="warn"
                        type="button"
                        (click)="syncDates()">Sync Dates</button>
            }
        </div>
        <div class="result_row">
            <span class="label">Last updated</span>
            <span class="value">{{formatTimestamp(domainInfo()!.updated)}}</span>
            @if (updatedMismatch()) {
                <span class="error"><mat-icon>error</mat-icon> EPP: {{formatTimestamp(eppDomainInfo()?.updated)}}</span>
            }
        </div>
        <div class="result_row">
            <span class="label">Transferred out</span>
            <span class="value">{{formatTimestamp(domainInfo()!.transferred_out)}}</span>
        </div>
        <div class="result_row">
            <span class="label">Status</span>
            <span class="value">
                @if (domainInfo()!.status && domainInfo()!.status.length == 0) {
                    (none)
                }
                @else {
                    @for (status of domainInfo()!.status; track $index) {
                        <span class="status"
                              [ngClass]="{warn: isServerStatus(status)}">{{status}}</span>
                    }
                }
            </span>
            @if (eppBusy()) {
                <mat-spinner [diameter]="15"></mat-spinner>
            }
        </div>
        @if (!eppBusy() && statusMismatch()) {
            <div class="result_row">
                <span class="label dummy"></span>
                <span class="error"><mat-icon>error</mat-icon> EPP: {{getEppStatus()}}</span>
                <button id="sync_status"
                        mat-raised-button
                        color="warn"
                        type="button"
                        (click)="syncStatus()">Sync Status</button>
            </div>
        }
        <div class="result_row">
            <span class="label">Auth code</span>
            @if (!eppBusy()) {
                <span class="value">{{eppDomainInfo()?.auth_code}}</span>
                @if (eppDomainInfo()?.auth_code) {
                    <button mat-icon-button color="primary"
                            matTooltip="Copy to clipboard"
                            (click)="copyAuthCode()">
                        <mat-icon>content_copy</mat-icon>
                    </button>
                }
            }
            @if (eppBusy()) {
                <mat-spinner [diameter]="15"></mat-spinner>
            }
        </div>
    </div>

    <div class="button_row">
        <div id="main_buttons">
            <button mat-raised-button
                    color="warn"
                    type="button"
                    [disabled]="eppBusy() || isBusy() || !canUnLock()"
                    (click)="unlock()">Unlock</button>
            <button mat-raised-button
                    color="warn"
                    type="button"
                    [disabled]="eppBusy() || isBusy() || !canLock()"
                    (click)="lock()">Lock</button>
            <button mat-raised-button
                    color="warn"
                    type="button"
                    [disabled]="eppBusy() || isBusy() || !canTransfer()"
                    (click)="approveTransfer()">Approve Transfer</button>
        </div>
        <div id="ns_groups">
            <button mat-raised-button
                    color="warn"
                    type="button"
                    [disabled]="eppBusy() || isBusy() || !canChangeGroup() || !hasDifferentGroup()"
                    (click)="changeGroup()">Change NS Group to:</button>

            <mat-form-field>
                <mat-select [(ngModel)]="selectedGroup"
                            id="group_select"
                            [disabled]="!canChangeGroup()">
                    @for (g of groups(); track g.name) {
                        <mat-option [value]="g.name">
                            {{g.name}}
                        </mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="delete_row">
        <span>
            <span class="label">In delete grace period</span>
            <span class="yes_no" [innerHTML]="inDeleteGracePeriod()? 'yes':'no'"></span>
        </span>

        <mat-form-field id="code_field">
            <mat-label>2FA code to authorize</mat-label>
            <input matInput
                   placeholder="6-digit code"
                   [disabled]="eppBusy() || isBusy() || !couldBeDeleted()"
                   [(ngModel)]="allowDeleteCode">
        </mat-form-field>

        <button mat-raised-button
                color="accent"
                type="button"
                [disabled]="eppBusy() || isBusy() || !canAllowDelete()"
                (click)="authorizeDelete()">Authorize Delete</button>

        <button mat-raised-button
                color="warn"
                type="button"
                [disabled]="eppBusy() || isBusy() || !canDelete()"
                (click)="delete()">Delete</button>

    </div>

    <div>
        <span class="section">Domain Transactions:</span>
        @if (!isBusy() && !hasTransactions()) {
            <span>None</span>
        }
    </div>
}
<div [hidden]="isBusy() || !hasPages()" class="paginator_row">
    <mat-paginator #paginator
                   [length]="transactions().length"
                   [pageIndex]="0"
                   [pageSize]="DEFAULT_PAGE_SIZE"
                   [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
</div>
<div id="table_scroller">
    @if (!isBusy() && !!dataSource() && transactions().length) {
        <table mat-table [dataSource]="dataSource()!">

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>ID</th>
                <td mat-cell *matCellDef="let trans" [innerHTML]="trans.transId"></td>
            </ng-container>

            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef>User</th>
                <td mat-cell *matCellDef="let trans" [innerHTML]="userEmail(trans.userId)"></td>
            </ng-container>

            <ng-container matColumnDef="op">
                <th mat-header-cell *matHeaderCellDef>Operation</th>
                <td mat-cell *matCellDef="let trans">{{trans.operation}}</td>
            </ng-container>

            <ng-container matColumnDef="started">
                <th mat-header-cell *matHeaderCellDef>Date</th>
                <td mat-cell *matCellDef="let trans">{{formatTimestamp(trans.startDate)}}</td>
            </ng-container>

            <ng-container matColumnDef="notes">
                <th mat-header-cell *matHeaderCellDef>Notes</th>
                <td mat-cell *matCellDef="let trans">{{trans.notes}}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let trans">
                    <button mat-raised-button
                            class="detail_button"
                            color="primary"
                            (click)="showDetail(trans)">Detail</button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row
                *matRowDef="let score; columns: columnsToDisplay"></tr>

        </table>
    }
</div>
