import {
    ChangeDetectionStrategy,
    Component,
    computed,
    ElementRef,
    OnInit,
    signal,
    Signal,
    viewChild
} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {BulkResponse, DomainService, NameServerGroup} from "../../services/domain.service";
import {BusyService} from "../../services/busy.service";
import {finalize} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {AlertService} from "../../services/alert.service";
import {Observable} from "rxjs";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {UtilService} from "../../services/util.service";
import {FormsModule} from "@angular/forms";
import {MatInput} from "@angular/material/input";
import {AuthService} from "../../services/auth.service";

type BulkOperation = (domains:string[]) => Observable<BulkResponse>;

@Component({
    standalone: true,
    imports: [
        MatButton,
        MatFormField,
        MatOption,
        MatSelect,
        FormsModule,
        MatLabel,
        MatInput
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './bulk.component.html',
    styleUrl: './bulk.component.scss'
})
export class BulkComponent implements OnInit {

    setPickUpload = viewChild<ElementRef>('setPickUpload');
    clearPickUpload = viewChild<ElementRef>('clearPickUpload');
    lockUpload = viewChild<ElementRef>('lockUpload');
    unlockUpload = viewChild<ElementRef>('unlockUpload');
    dnsUpload = viewChild<ElementRef>('dnsUpload');
    deleteUpload = viewChild<ElementRef>('deleteUpload');

    groups = signal<NameServerGroup[]>([]);
    selectedGroup = signal('');
    // 2FA code to authorize the enabling of the Delete button
    allowDeleteCode = signal('');
    // We have a 2FA code that looks good enough to attempt validation
    canAllowDelete = computed(() => /^\d{6}$/.test(this.allowDeleteCode()));
    // Validation of 2FA code has succeeded, so bulk delete scheduling can proceed
    canDelete = signal(false);


    constructor(private domainService: DomainService,
                private alertService: AlertService,
                private authService: AuthService,
                private utilService: UtilService,
                private busyService: BusyService) {

    }

    ngOnInit() {
        this.busyService.showBusy();
        this.utilService.getGroups().pipe(
            finalize(() => { this.busyService.showNotBusy() })
        ).subscribe( {
            next: (groups) => {
                this.groups.set(groups);
            },
            error: (err: HttpErrorResponse) => {
                console.error(err);
                this.alertService.error("Error retrieving name server groups: " + err.message);
            }
        });
    }

    setPickLoaded(ev: Event): void {
        this.changePickLoaded(ev, true);
    }

    clearPickLoaded(ev: Event): void {
        this.changePickLoaded(ev, false);
    }

    private changePickLoaded(ev: Event, flag: boolean): void {
        const elem = ev.target as HTMLInputElement;
        if (elem.files && elem.files[0]) {
            const file = elem.files[0];
            console.log("file:", file.name);
            let reader = new FileReader();
            reader.addEventListener("load", (e) => {
                const domains = this.preprocessList(reader.result as string);
                this.changePicks(domains, flag);
                this.uploadReset(flag? this.setPickUpload()! : this.clearPickUpload()!);
            }, false);
            reader.readAsText(file);
        }
    }

    private uploadReset(fileInput: ElementRef): void {
        // Must also allow the file input element to forget its prior file, in case user wants to upload the same
        // file again. Otherwise, the change event doesn't get triggered because nothing has changed. This seems to be
        // the cleanest way to achieve it.
        fileInput.nativeElement.type = "";
        fileInput.nativeElement.type = "file";
    }

    private preprocessList(body: string): string[] {
        // Get one domain from each line, as lowercase, removing leading or trailing spaces, and skipping empty lines
        let domains = body.split('\n')
            .map((line) => line.toLocaleLowerCase().trim())
            .filter(line => line.length > 0);
        // Now remove duplicates and sort it
        return [...new Set(domains)].sort();
    }

    private changePicks(domains: string[], flag: boolean): void {
        console.log(`Uploading ${domains.length} domains`);
        this.busyService.showBusy();
        const obs: Observable<BulkResponse> = flag? this.domainService.bulkSetPicks(domains)
                                                  : this.domainService.bulkClearPicks(domains);
        obs.pipe(
            finalize(() => this.busyService.showNotBusy())
        ).subscribe({
            next: (out: BulkResponse) => {
                let msg = `Updated domains: ${out.changed}`;
                if (out.missing.length > 0) {
                    msg += "<br/><p>The following domains were not found:</p>" + out.missing.join("<br/>");
                }
                this.alertService.info(msg);
            },
            error: (err: HttpErrorResponse) => {
                this.alertService.error("Error changing domains as picks: " + err.error?.message || err.error || err.message);
            }
        })
    }

    lockLoaded(ev: Event): void {
        this.handleQueuedOpLoaded(ev.target as HTMLInputElement, this.lockUpload, 'lock',
                                  (d) => this.domainService.bulkLockDomains(d));
    }

    unlockLoaded(ev: Event): void {
        this.handleQueuedOpLoaded(ev.target as HTMLInputElement, this.unlockUpload, 'unlock',
                                  (d) => this.domainService.bulkUnlockDomains(d));
    }

    dnsLoaded(ev: Event): void {
        this.handleQueuedOpLoaded(ev.target as HTMLInputElement, this.dnsUpload, 'ns_change',
            (d) => this.domainService.bulkChangeDomainsNsGroup(d, this.selectedGroup()));
    }

    deleteClicked(): void {
        const question = "Are you sure you want to delete a list of domains?<br/>This action cannot be undone!";
        this.alertService.confirm(question).subscribe({
            next: (confirmed) => {
                if (confirmed)
                    this.deleteUpload()!.nativeElement.click();
            }
        });
    }

    deleteLoaded(ev: Event): void {
        this.handleQueuedOpLoaded(ev.target as HTMLInputElement, this.deleteUpload, 'delete',
                                  (d) => this.domainService.bulkDeleteDomains(d));
    }

    private handleQueuedOpLoaded(inputElem: HTMLInputElement,
                                 childSignal: Signal<ElementRef<any> | undefined>,
                                 opName: string, op: BulkOperation) {
        if (inputElem.files && inputElem.files[0]) {
            const file = inputElem.files[0];
            console.log("file:", file.name);
            let reader = new FileReader();
            reader.addEventListener("load", (e) => {
                const domains = this.preprocessList(reader.result as string);
                this.queueBulkOperation(domains, opName, op);
                this.uploadReset(childSignal()!);
            }, false);
            reader.readAsText(file);
        }
    }

    private queueBulkOperation(domains: string[], opName: string, op: BulkOperation) {
        console.log(`Uploading ${domains.length} domains`);
        this.busyService.showBusy();
        op(domains).pipe(
            finalize(() => this.busyService.showNotBusy())
        ).subscribe({
            next: (out: BulkResponse) => {
                let msg = `Queued domains: ${out.changed}`;
                if (out.missing.length > 0) {
                    msg += "<br/><p>The following domains were not found:</p>" + out.missing.join("<br/>");
                }
                this.alertService.info(msg);
            },
            error: (err: HttpErrorResponse) => {
                this.alertService.error(`Error queuing ${opName} operation: ` + err.error?.message || err.error || err.message);
            }
        })
    }

    /** Validates the entered 2FA code and, if  valid, enables the Delete button
     */
    authorizeDelete() {
        this.busyService.showBusy()
        this.authService.validateMfaCode(this.allowDeleteCode()).pipe(
            finalize(() => {
                this.busyService.showNotBusy()
            })
        ).subscribe( {
            next: (valid) => {
                if (!valid)
                    this.alertService.error("The supplied 2FA code is not valid");
                else {
                    this.allowDeleteCode.set('')
                    this.canDelete.set(true);
                }
            },
            error: (err: HttpErrorResponse) => {
                this.alertService.error("Error validating 2FA code: " + err.error?.message || err.message);
            }
        });
    }
}
