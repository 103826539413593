import {Injectable} from '@angular/core';
import {AlertService} from "./alert.service";
import {Observable, of} from "rxjs";
import {User} from "./auth.service";
import {tap} from "rxjs/operators";
import {TransactionService} from "./transaction.service";
import {StateService} from "./state.service";
import {DomainService, NameServerGroup} from "./domain.service";

@Injectable({
    providedIn: 'root'
})
export class UtilService {

    constructor(private alertService: AlertService,
                private transactionService: TransactionService,
                private domainService: DomainService,
                private state: StateService) {
    }

    getErrorHandler(defaultMessage: string|null = null): (err:any) => void {
        return (error:any) => {
            if (error.error instanceof Blob)  {
                error.error.text().then( (text:any) => {
                    const info = JSON.parse(text);
                    let message = info.message || defaultMessage || "Error";
                    this.alertService.error(message);
                });
            } else if (typeof error.error === 'string') {
                let message = error.error;
                this.alertService.error(message);
            } else {
                let message = error.error && error.error?.message || defaultMessage || "Error";
                this.alertService.error(message);
            }
        }
    }

    setBodyBusy(flag: boolean) {
        if (flag)
            document.body.classList.add("busy");
        else
            document.body.classList.remove("busy");
    }

    getUsers(): Observable<User[]> {
        const users = this.state.get('users')();
        if (users)
            return of(users);
        else {
            return this.transactionService.getAllUsers().pipe(
                tap((users) => {
                    users = [{id: 0, email: "ALL"}].concat(users);
                    this.state.set('users', users);
                })
            );
        }
    }

    getGroups(): Observable<NameServerGroup[]> {
        const groups = this.state.get('groups')();
        if (groups)
            return of(groups);
        else {
            return this.domainService.getNameServerGroups().pipe(
                tap((groups) => {
                    this.state.set('groups', groups);
                })
            );
        }
    }

}
