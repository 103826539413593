<h1>Bulk Domain Operations</h1>
<p>Various operations that act on a list of domains uploaded in a text file.</p>

<div class="op_row">
    <input type="file" class="hidden"
           (change)="setPickLoaded($event)"
           #setPickUpload>
    <button mat-raised-button color="primary"
            (click)="setPickUpload.click()">Set as Picks</button>

    <input type="file" class="hidden"
           (change)="clearPickLoaded($event)"
           #clearPickUpload>
    <button mat-raised-button color="primary"
            (click)="clearPickUpload.click()">Clear as Picks</button>
</div>

<div class="op_row">
    <input type="file" class="hidden"
            (change)="lockLoaded($event)"
            #lockUpload>
    <button mat-raised-button
            color="accent"
            (click)="lockUpload.click()">Lock Domains</button>

    <input type="file" class="hidden"
           (change)="unlockLoaded($event)"
           #unlockUpload>
    <button mat-raised-button
            color="accent"
            (click)="unlockUpload.click()">Unlock Domains</button>
</div>

<div class="op_row">
    <mat-form-field>
        <mat-label>Select target group</mat-label>
        <mat-select [(ngModel)]="selectedGroup"
                    id="group_select">
            @for (g of groups(); track g.name) {
                <mat-option [value]="g.name">
                    {{g.name}}
                </mat-option>
            }
        </mat-select>
    </mat-form-field>

    <input type="file" class="hidden"
           (change)="dnsLoaded($event)"
           #dnsUpload>
    <button mat-raised-button
            color="accent"
            [disabled]="!selectedGroup()"
            (click)="dnsUpload.click()">Change NS Group</button>
</div>

<div class="op_row">
    <mat-form-field id="code_field">
        <mat-label>2FA code to authorize</mat-label>
        <input matInput
               placeholder="6-digit code"
               [(ngModel)]="allowDeleteCode">
    </mat-form-field>

    <button mat-raised-button
            color="accent"
            type="button"
            [disabled]="!canAllowDelete()"
            (click)="authorizeDelete()">Authorize Bulk Delete</button>

    <input type="file" class="hidden"
           (change)="deleteLoaded($event)"
           #deleteUpload>
    <button mat-raised-button
            color="warn"
            type="button"
            [disabled]="!canDelete()"
            (click)="deleteClicked()">Delete</button>
</div>