import {ChangeDetectionStrategy, Component, signal} from '@angular/core';
import {NgClass} from "@angular/common";
import {DomainComponent} from "../domain/domain.component";
import {DomainsSummaryComponent} from "../../controls/domains-summary/domains-summary.component";

@Component({
    standalone: true,
    imports: [
        NgClass,
        DomainComponent,
        DomainsSummaryComponent
    ],
    templateUrl: './domains-section.component.html',
    styleUrl: './domains-section.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DomainsSectionComponent {

    currentSection = signal("domain")

    select(section: string) {
        this.currentSection.set(section);
    }
}
