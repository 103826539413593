<div id="submenu">
    <span class="selector"
          (click)="select('domain')"
          [ngClass]="{link: currentSection() != 'domain', selected: currentSection() == 'domain'}">Domain Info</span>

    <span class="selector"
          (click)="select('status')"
          [ngClass]="{link: currentSection() != 'status', selected: currentSection() == 'status'}">Status Summary</span>
</div>
@if (currentSection() == 'domain') {
    <app-domain></app-domain>
} @else {
    <app-domains-summary></app-domains-summary>
}