import {ChangeDetectionStrategy, Component, OnInit, signal, WritableSignal} from '@angular/core';
import {DomainService, StatusSummary} from "../../services/domain.service";
import {BusyService} from "../../services/busy.service";
import {finalize} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {AlertService} from "../../services/alert.service";
import {DecimalPipe} from "@angular/common";
import {CacheService} from "../../services/cache.service";

@Component({
    selector: 'app-domains-summary',
    standalone: true,
    imports: [
        DecimalPipe
    ],
    templateUrl: './domains-summary.component.html',
    styleUrl: './domains-summary.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DomainsSummaryComponent implements OnInit {

    summary: WritableSignal<StatusSummary|null> = signal(null);

    constructor(private domainService: DomainService,
                private alertService: AlertService,
                private cache: CacheService,
                private busyService: BusyService) {
    }

    ngOnInit() {
        if (this.cache.has('status_summary')) {
            this.summary.set(this.cache.get('status_summary'));
            return;
        }
        this.busyService.showBusy();
        this.domainService.getStatusSummary().pipe(
            finalize(() => {this.busyService.showNotBusy()})
        ).subscribe({
            next: (summary) => {
                this.summary.set(summary);
                this.cache.set('status_summary', summary);
            },
            error: (err: HttpErrorResponse)=> {
                console.error(err);
                this.alertService.error("Error retrieving domain status summary: " + err.message);
            }
        });
    }
}
